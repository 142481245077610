import { Injectable } from '@angular/core';
import { QrCodeAuthService } from '@swagger/index';
import { Observable, catchError } from 'rxjs';
import { HttpHelperService } from './http-helper.service';
import { ToastService } from './toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class QrCodeAuthWrapperService {
  constructor(
    private readonly qrCodeAuthService: QrCodeAuthService,
    private readonly toastService: ToastService,
    private readonly httpHelperService: HttpHelperService
  ) {}

  public getCode(silentLoading = true, silentError = false): Observable<string> {
    const options = this.httpHelperService.getDefaultBypassLoadingOptions(silentLoading);

    return this.qrCodeAuthService.qrCodeAuthControllerGetCode0('body', false, options).pipe(
      catchError((error) => {
        if (!silentError) {
          this.toastService.showDefaultErrorToast();
        }

        throw error;
      })
    );
  }
}
