import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hyphen',
})
export class EmptyDataHyphenPipe implements PipeTransform {
  public transform(value: any): string {
    if (value === null || value === undefined || (typeof value === 'string' && value?.trim() === '')) {
      return '-';
    }

    if (typeof value === 'number' && isNaN(value)) {
      return '-';
    }

    return value;
  }
}
