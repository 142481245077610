import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Region } from '@core/enums/region.enum';

import { MailerooWrapperService } from '@core/services/maileroo-wrapper.service';
import { SessionStorageKey } from '@shared/enums/session-storage-key.enum';
import { ExtendedControl, emailVerificationValidator } from '@shared/validators/email-verification.validator';
import { take } from 'rxjs';
import { LoginForm } from '../../interfaces/login-form.interface';

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  public form!: FormGroup<LoginForm>;

  @Input() public region!: Region;

  @Output() public login: EventEmitter<string> = new EventEmitter();

  @Output() public toggleLoginSignup: EventEmitter<void> = new EventEmitter();

  @Output() public setSessionStorage: EventEmitter<string | null> = new EventEmitter();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly mailerooService: MailerooWrapperService
  ) {}

  public ngOnInit(): void {
    this.form = this.formBuilder.group<LoginForm>({
      email: this.formBuilder.control(sessionStorage.getItem(SessionStorageKey.registerEmail), {
        validators: [Validators.required, Validators.email],
        asyncValidators: [emailVerificationValidator(this.mailerooService, true)],
      }),
    });

    if (this.form.controls.email.value) {
      this.form.controls.email.markAsTouched();
    }
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();

    if (this.form.pending) {
      this.scheduleSubmit();

      return;
    }

    if (this.form.valid) {
      this.login.emit(this.form.value.email!);
    }
  }

  private scheduleSubmit(): void {
    this.form.statusChanges.pipe(take(1)).subscribe((status) => {
      if (status !== 'PENDING') {
        this.onSubmit();
      }
    });
  }

  public switchToSignup(): void {
    this.toggleLoginSignup.emit();
  }

  public onChange(): void {
    this.setSessionStorage.emit(this.form.value.email);
  }

  public onSuggestionUse(correctedEmail: string): void {
    this.form.controls.email.patchValue(correctedEmail);
    this.form.controls.email.updateValueAndValidity();
    this.onChange();
  }

  public onClearEmail(): void {
    this.form.controls.email.reset();
    sessionStorage.removeItem(SessionStorageKey.registerEmail);
  }

  public get emailDomainSuggestionWarning(): string {
    return (this.form.controls.email as ExtendedControl).warnings?.domainSuggestion;
  }
}
