/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestAllUsersBySearchWithLimitedDetails } from '../model/models';
import { ApiRequestEmployerStatistic } from '../model/models';
import { ApiRequestFullStatistics } from '../model/models';
import { ApiRequestUsersWithLimitedDetails } from '../model/models';
import { ApiResponseFullStatisticForUser } from '../model/models';
import { ApiResponseUserGrowthChartEntry } from '../model/models';
import { ApiResponseUserWithCommuteKPIs } from '../model/models';
import { ApiResponseUserWithLimitedDetails } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface UserStatisticsServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    userStatisticsControllerFindTotalOnboardingCompletedUsersByEmployerCode0(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    userStatisticsControllerFindTotalOnboardingCompletedUsersByEmployerCode1(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param apiRequestAllUsersBySearchWithLimitedDetails 
     */
    userStatisticsControllerGetAllBySearchWithLimitedDetails0(apiRequestAllUsersBySearchWithLimitedDetails: ApiRequestAllUsersBySearchWithLimitedDetails, extraHttpRequestParams?: any): Observable<Array<ApiResponseUserWithLimitedDetails>>;

    /**
     * 
     * 
     * @param apiRequestAllUsersBySearchWithLimitedDetails 
     */
    userStatisticsControllerGetAllBySearchWithLimitedDetails1(apiRequestAllUsersBySearchWithLimitedDetails: ApiRequestAllUsersBySearchWithLimitedDetails, extraHttpRequestParams?: any): Observable<Array<ApiResponseUserWithLimitedDetails>>;

    /**
     * 
     * 
     * @param apiRequestFullStatistics 
     */
    userStatisticsControllerGetFullStatisticsByEmployerId0(apiRequestFullStatistics: ApiRequestFullStatistics, extraHttpRequestParams?: any): Observable<Array<ApiResponseFullStatisticForUser>>;

    /**
     * 
     * 
     * @param apiRequestFullStatistics 
     */
    userStatisticsControllerGetFullStatisticsByEmployerId1(apiRequestFullStatistics: ApiRequestFullStatistics, extraHttpRequestParams?: any): Observable<Array<ApiResponseFullStatisticForUser>>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    userStatisticsControllerGetUserGrowthByEmployer0(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<Array<ApiResponseUserGrowthChartEntry>>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    userStatisticsControllerGetUserGrowthByEmployer1(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<Array<ApiResponseUserGrowthChartEntry>>;

    /**
     * 
     * 
     * @param employerId 
     * @param username 
     * @param from 
     * @param to 
     */
    userStatisticsControllerGetUserWithCommuteKPIs0(employerId: string, username: string, from: string, to: string, extraHttpRequestParams?: any): Observable<ApiResponseUserWithCommuteKPIs>;

    /**
     * 
     * 
     * @param employerId 
     * @param username 
     * @param from 
     * @param to 
     */
    userStatisticsControllerGetUserWithCommuteKPIs1(employerId: string, username: string, from: string, to: string, extraHttpRequestParams?: any): Observable<ApiResponseUserWithCommuteKPIs>;

    /**
     * 
     * 
     * @param apiRequestUsersWithLimitedDetails 
     */
    userStatisticsControllerGetUsersWithLimitedDetails0(apiRequestUsersWithLimitedDetails: ApiRequestUsersWithLimitedDetails, extraHttpRequestParams?: any): Observable<Array<ApiResponseUserWithLimitedDetails>>;

    /**
     * 
     * 
     * @param apiRequestUsersWithLimitedDetails 
     */
    userStatisticsControllerGetUsersWithLimitedDetails1(apiRequestUsersWithLimitedDetails: ApiRequestUsersWithLimitedDetails, extraHttpRequestParams?: any): Observable<Array<ApiResponseUserWithLimitedDetails>>;

}
