import { Injectable } from '@angular/core';
import { ApiRequestSelfOnboarding, SelfOnboardingService } from '@swagger/index';
import { Observable, catchError } from 'rxjs';
import { ToastService } from './toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class SelfOnboardingWrapperServiceService {
  constructor(
    private readonly selfOnboardingService: SelfOnboardingService,
    private readonly toastService: ToastService
  ) {}

  public selfOnboarding(apiRequestSelfOnboarding: ApiRequestSelfOnboarding): Observable<any> {
    return this.selfOnboardingService.selfOnboardingControllerSelfOnboard0(apiRequestSelfOnboarding).pipe(
      catchError((error) => {
        this.toastService.show(true, 'onboarding.createOrganizationError');
        throw error;
      })
    );
  }
}
