/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestAllUsersListPagination } from '../model/models';
import { ApiRequestCountUsersByWorkAddress } from '../model/models';
import { ApiRequestDeleteUsers } from '../model/models';
import { ApiRequestRegenerateRoutesByEmployerId } from '../model/models';
import { ApiRequestUserUpdateModel } from '../model/models';
import { ApiRequestUsersListByEmployerPagination } from '../model/models';
import { ApiResponseDisplayUser } from '../model/models';
import { ApiResponseSuccessModel } from '../model/models';
import { ApiResponseUserModel } from '../model/models';
import { ApiResponseUserStatistics } from '../model/models';
import { ApiResponseUsersListPagination } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface UserServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param apiRequestCountUsersByWorkAddress 
     */
    userControllerCountUsersByWorkAddress0(apiRequestCountUsersByWorkAddress: ApiRequestCountUsersByWorkAddress, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param apiRequestCountUsersByWorkAddress 
     */
    userControllerCountUsersByWorkAddress1(apiRequestCountUsersByWorkAddress: ApiRequestCountUsersByWorkAddress, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     */
    userControllerDelete0(extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     */
    userControllerDelete1(extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param username 
     */
    userControllerDeleteAsAdmin0(username: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param username 
     */
    userControllerDeleteAsAdmin1(username: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * returns the usernames where the deletion failed
     * 
     * @param apiRequestDeleteUsers 
     */
    userControllerDeleteByUsernames0(apiRequestDeleteUsers: ApiRequestDeleteUsers, extraHttpRequestParams?: any): Observable<Array<string>>;

    /**
     * returns the usernames where the deletion failed
     * 
     * @param apiRequestDeleteUsers 
     */
    userControllerDeleteByUsernames1(apiRequestDeleteUsers: ApiRequestDeleteUsers, extraHttpRequestParams?: any): Observable<Array<string>>;

    /**
     * 
     * 
     */
    userControllerGet0(extraHttpRequestParams?: any): Observable<ApiResponseUserModel>;

    /**
     * 
     * 
     */
    userControllerGet1(extraHttpRequestParams?: any): Observable<ApiResponseUserModel>;

    /**
     * 
     * 
     */
    userControllerGet2(extraHttpRequestParams?: any): Observable<ApiResponseUserModel>;

    /**
     * 
     * 
     */
    userControllerGet3(extraHttpRequestParams?: any): Observable<ApiResponseUserModel>;

    /**
     * 
     * 
     * @param employerId 
     */
    userControllerGetAllUsersByEmployerWithStatistics0(employerId: string, extraHttpRequestParams?: any): Observable<Array<ApiResponseUserStatistics>>;

    /**
     * 
     * 
     * @param employerId 
     */
    userControllerGetAllUsersByEmployerWithStatistics1(employerId: string, extraHttpRequestParams?: any): Observable<Array<ApiResponseUserStatistics>>;

    /**
     * 
     * 
     * @param apiRequestAllUsersListPagination 
     */
    userControllerGetAllUsersWithPagination0(apiRequestAllUsersListPagination: ApiRequestAllUsersListPagination, extraHttpRequestParams?: any): Observable<ApiResponseUsersListPagination>;

    /**
     * 
     * 
     * @param apiRequestAllUsersListPagination 
     */
    userControllerGetAllUsersWithPagination1(apiRequestAllUsersListPagination: ApiRequestAllUsersListPagination, extraHttpRequestParams?: any): Observable<ApiResponseUsersListPagination>;

    /**
     * 
     * 
     * @param username 
     */
    userControllerGetOne0(username: string, extraHttpRequestParams?: any): Observable<ApiResponseUserModel>;

    /**
     * 
     * 
     * @param username 
     */
    userControllerGetOne1(username: string, extraHttpRequestParams?: any): Observable<ApiResponseUserModel>;

    /**
     * 
     * 
     * @param username 
     * @param employerId 
     */
    userControllerGetUserByUsernameWithStatistics0(username: string, employerId: string, extraHttpRequestParams?: any): Observable<ApiResponseUserStatistics>;

    /**
     * 
     * 
     * @param username 
     * @param employerId 
     */
    userControllerGetUserByUsernameWithStatistics1(username: string, employerId: string, extraHttpRequestParams?: any): Observable<ApiResponseUserStatistics>;

    /**
     * 
     * 
     * @param apiRequestUsersListByEmployerPagination 
     */
    userControllerGetUsersByEmployerWithPagination0(apiRequestUsersListByEmployerPagination: ApiRequestUsersListByEmployerPagination, extraHttpRequestParams?: any): Observable<ApiResponseUsersListPagination>;

    /**
     * 
     * 
     * @param apiRequestUsersListByEmployerPagination 
     */
    userControllerGetUsersByEmployerWithPagination1(apiRequestUsersListByEmployerPagination: ApiRequestUsersListByEmployerPagination, extraHttpRequestParams?: any): Observable<ApiResponseUsersListPagination>;

    /**
     * 
     * 
     */
    userControllerGetUsersConnectedToSameNetworks0(extraHttpRequestParams?: any): Observable<Array<ApiResponseDisplayUser>>;

    /**
     * 
     * 
     */
    userControllerGetUsersConnectedToSameNetworks1(extraHttpRequestParams?: any): Observable<Array<ApiResponseDisplayUser>>;

    /**
     * 
     * 
     * @param email 
     */
    userControllerIsEmailRegistered0(email: string, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param email 
     */
    userControllerIsEmailRegistered1(email: string, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param domain 
     */
    userControllerIsPrivateDomainAlreadyUsed0(domain: string, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param domain 
     */
    userControllerIsPrivateDomainAlreadyUsed1(domain: string, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param apiRequestRegenerateRoutesByEmployerId 
     */
    userControllerRegenerateRoutesByEmployerId0(apiRequestRegenerateRoutesByEmployerId: ApiRequestRegenerateRoutesByEmployerId, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestRegenerateRoutesByEmployerId 
     */
    userControllerRegenerateRoutesByEmployerId1(apiRequestRegenerateRoutesByEmployerId: ApiRequestRegenerateRoutesByEmployerId, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestUserUpdateModel 
     */
    userControllerUpdate0(apiRequestUserUpdateModel: ApiRequestUserUpdateModel, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param apiRequestUserUpdateModel 
     */
    userControllerUpdate1(apiRequestUserUpdateModel: ApiRequestUserUpdateModel, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

}
