/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestLandingPageRecommendationEmailModel } from '../model/models';
import { ApiRequestLandingPageRecommendationModel } from '../model/models';
import { ApiResponseLandingPageRecommendationModel } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface LandingPageRecommendationServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param apiRequestLandingPageRecommendationModel 
     */
    landingPageRecommendationControllerGet0(apiRequestLandingPageRecommendationModel: ApiRequestLandingPageRecommendationModel, extraHttpRequestParams?: any): Observable<ApiResponseLandingPageRecommendationModel>;

    /**
     * 
     * 
     * @param apiRequestLandingPageRecommendationModel 
     */
    landingPageRecommendationControllerGet1(apiRequestLandingPageRecommendationModel: ApiRequestLandingPageRecommendationModel, extraHttpRequestParams?: any): Observable<ApiResponseLandingPageRecommendationModel>;

    /**
     * 
     * 
     * @param apiRequestLandingPageRecommendationEmailModel 
     */
    landingPageRecommendationControllerSendEmail0(apiRequestLandingPageRecommendationEmailModel: ApiRequestLandingPageRecommendationEmailModel, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestLandingPageRecommendationEmailModel 
     */
    landingPageRecommendationControllerSendEmail1(apiRequestLandingPageRecommendationEmailModel: ApiRequestLandingPageRecommendationEmailModel, extraHttpRequestParams?: any): Observable<{}>;

}
