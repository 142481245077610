import { Injectable } from '@angular/core';
import { UserStatisticsService } from '@swagger/api/api';
import {
  ApiResponseFullStatisticForUser,
  ApiResponseUserWithCommuteKPIs,
  ApiResponseUserWithLimitedDetails,
  ChildAddressModel,
} from '@swagger/model/models';
import { Observable, catchError } from 'rxjs';
import { EmployerWrapperService } from '../employer/employer-wrapper.service';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class UserStatisticsWrapperService {
  constructor(
    private readonly userStatisticsService: UserStatisticsService,
    private readonly employerWrapperService: EmployerWrapperService,
    private readonly toastService: ToastService
  ) {}

  public getUserWithCommuteKPIs(
    username: string,
    from: string,
    to: string
  ): Observable<ApiResponseUserWithCommuteKPIs> {
    return this.userStatisticsService
      .userStatisticsControllerGetUserWithCommuteKPIs0(this.employerWrapperService.employerId, username, from, to)
      .pipe(
        catchError((error) => {
          this.toastService.show(true, 'commuteHistory.errorLoadingCommuteKPIsOfUser');
          throw error;
        })
      );
  }

  public getAllBySearchWithLimitedDetails(searchText: string): Observable<ApiResponseUserWithLimitedDetails[]> {
    return this.userStatisticsService.userStatisticsControllerGetAllBySearchWithLimitedDetails0({ searchText });
  }

  public getUsersWithLimitedDetails(usernames: string[]): Observable<ApiResponseUserWithLimitedDetails[]> {
    return this.userStatisticsService.userStatisticsControllerGetUsersWithLimitedDetails0({ usernames });
  }

  public getFullStatisticsByEmployerId(
    from: Date,
    to: Date,
    addresses?: ChildAddressModel[]
  ): Observable<ApiResponseFullStatisticForUser[]> {
    return this.userStatisticsService
      .userStatisticsControllerGetFullStatisticsByEmployerId0({
        employerId: this.employerWrapperService.employerId,
        from: from.toISOString(),
        to: to.toISOString(),
        addresses,
      })
      .pipe(
        catchError((error) => {
          this.toastService.show(true, 'dashboard.errorLoadingStatisticsMessage');
          throw error;
        })
      );
  }
}
