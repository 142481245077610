/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LocationPermissionGrantTypeEnum } from './locationPermissionGrantTypeEnum';
import { TrackingGeofencingMode } from './trackingGeofencingMode';
import { TrackingMode } from './trackingMode';
import { LocationPermissionPrecisionEnum } from './locationPermissionPrecisionEnum';
import { SentianceStateEnum } from './sentianceStateEnum';
import { TripModeEnum } from './tripModeEnum';
import { ChildAutomaticTrackingConfiguration } from './childAutomaticTrackingConfiguration';


export interface ChildTrackingInfo { 
    locationPermissionGrantType?: LocationPermissionGrantTypeEnum | null;
    locationPermissionPrecision?: LocationPermissionPrecisionEnum | null;
    trackingMode?: TrackingMode;
    sentianceState?: SentianceStateEnum | null;
    geofencingMode?: TrackingGeofencingMode | null;
    selectedMode?: TripModeEnum;
    osName: string;
    osVersion: string;
    appVersion: string;
    deviceName: string;
    appOpened?: string | null;
    motionPermissionGrantedDate?: string | null;
    locationPermissionGrantedDate?: string | null;
    batteryOptimizationTurnedOffDate?: string | null;
    sentianceStateUpdatedDate?: string | null;
    automaticTrackingConfiguration?: ChildAutomaticTrackingConfiguration;
}



