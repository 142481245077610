/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestAuthChangeEmail } from '../model/models';
import { ApiRequestAuthEmailVerificationVerify } from '../model/models';
import { ApiRequestAuthLoginModel } from '../model/models';
import { ApiRequestAuthRefreshModel } from '../model/models';
import { ApiRequestAuthSocialLoginModel } from '../model/models';
import { ApiRequestFinishEmailLoginModel } from '../model/models';
import { ApiRequestResendVerificationEmail } from '../model/models';
import { ApiResponseAuthRefreshModel } from '../model/models';
import { ApiResponseSuccessModel } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface AuthServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param apiRequestAuthChangeEmail 
     */
    authControllerChangeEmail0(apiRequestAuthChangeEmail: ApiRequestAuthChangeEmail, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestAuthChangeEmail 
     */
    authControllerChangeEmail1(apiRequestAuthChangeEmail: ApiRequestAuthChangeEmail, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestFinishEmailLoginModel 
     */
    authControllerFinishLogin0(apiRequestFinishEmailLoginModel: ApiRequestFinishEmailLoginModel, extraHttpRequestParams?: any): Observable<ApiResponseAuthRefreshModel>;

    /**
     * 
     * 
     * @param apiRequestFinishEmailLoginModel 
     */
    authControllerFinishLogin1(apiRequestFinishEmailLoginModel: ApiRequestFinishEmailLoginModel, extraHttpRequestParams?: any): Observable<ApiResponseAuthRefreshModel>;

    /**
     * 
     * 
     * @param apiRequestAuthLoginModel 
     */
    authControllerLogin0(apiRequestAuthLoginModel: ApiRequestAuthLoginModel, extraHttpRequestParams?: any): Observable<ApiResponseAuthRefreshModel>;

    /**
     * 
     * 
     * @param apiRequestAuthLoginModel 
     */
    authControllerLogin1(apiRequestAuthLoginModel: ApiRequestAuthLoginModel, extraHttpRequestParams?: any): Observable<ApiResponseAuthRefreshModel>;

    /**
     * 
     * 
     */
    authControllerLogout0(extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     */
    authControllerLogout1(extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param apiRequestAuthRefreshModel 
     */
    authControllerRefresh0(apiRequestAuthRefreshModel: ApiRequestAuthRefreshModel, extraHttpRequestParams?: any): Observable<ApiResponseAuthRefreshModel>;

    /**
     * 
     * 
     * @param apiRequestAuthRefreshModel 
     */
    authControllerRefresh1(apiRequestAuthRefreshModel: ApiRequestAuthRefreshModel, extraHttpRequestParams?: any): Observable<ApiResponseAuthRefreshModel>;

    /**
     * 
     * 
     * @param apiRequestResendVerificationEmail 
     */
    authControllerResendEmailVerification0(apiRequestResendVerificationEmail: ApiRequestResendVerificationEmail, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestResendVerificationEmail 
     */
    authControllerResendEmailVerification1(apiRequestResendVerificationEmail: ApiRequestResendVerificationEmail, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestAuthSocialLoginModel 
     */
    authControllerSocialLogin0(apiRequestAuthSocialLoginModel: ApiRequestAuthSocialLoginModel, extraHttpRequestParams?: any): Observable<ApiResponseAuthRefreshModel>;

    /**
     * 
     * 
     * @param apiRequestAuthSocialLoginModel 
     */
    authControllerSocialLogin1(apiRequestAuthSocialLoginModel: ApiRequestAuthSocialLoginModel, extraHttpRequestParams?: any): Observable<ApiResponseAuthRefreshModel>;

    /**
     * 
     * 
     * @param apiRequestAuthEmailVerificationVerify 
     */
    authControllerVerifyEmail0(apiRequestAuthEmailVerificationVerify: ApiRequestAuthEmailVerificationVerify, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestAuthEmailVerificationVerify 
     */
    authControllerVerifyEmail1(apiRequestAuthEmailVerificationVerify: ApiRequestAuthEmailVerificationVerify, extraHttpRequestParams?: any): Observable<{}>;

}
