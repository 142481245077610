/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildTransportLocationModel } from './childTransportLocationModel';
import { ChildTransportUserDetailsModel } from './childTransportUserDetailsModel';
import { TripModeEnum } from './tripModeEnum';


export interface ChildTransportModel { 
    mode: TripModeEnum | null;
    userDetails: ChildTransportUserDetailsModel;
    startTime: string;
    endTime: string;
    externalId: string;
    startLocation: ChildTransportLocationModel;
    endLocation: ChildTransportLocationModel;
    lengthInMeters: number;
}



