import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { pageRoutes } from '@core/models/page-routes.model';
import cloneDeep from 'clone-deep';
import {
  Observable,
  ReplaySubject,
  catchError,
  concatMap,
  firstValueFrom,
  from,
  map,
  of,
  retry,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { CustomRouteReuseStrategy } from 'src/app/custom-route-reuse-strategy';
import {
  ApiRequestUpdateEmployer,
  ApiResponseEmployer,
  ApiResponseEmployerAppModel,
  ApiResponseEmployerListWithPagination,
  ApiResponseSelectableEmployer,
  ApiResponseSelectableForDashboardEmployer,
  ApiResponseSelectableForDashboardEmployerWithAddress,
  ApiResponseSimpleEmployer,
  EmployerService,
  SubscriptionStatusEnum,
} from '../../../api';
import { EmployerDetails } from '../../../post-login/employers/interfaces/employer-details.interface';
import { LocalStorageKey } from '../../../shared/enums/local-storage-key.enum';
import { HttpHelperService } from '../http-helper.service';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class EmployerWrapperService {
  private readonly employer$: ReplaySubject<ApiResponseEmployer> = new ReplaySubject(1);

  public employer!: ApiResponseEmployer;

  public get employerId(): string {
    return this.employer.id;
  }

  public get simpleEmployer(): ApiResponseSimpleEmployer {
    return this.employer as ApiResponseSimpleEmployer;
  }

  constructor(
    private readonly employerService: EmployerService,
    private readonly toastService: ToastService,
    private readonly httpHelperService: HttpHelperService,
    private readonly router: Router
  ) {}

  public getEmployerStream(): Observable<ApiResponseEmployer> {
    return this.employer$.asObservable();
  }

  public actAsEmployer(employer: ApiResponseEmployer, reload = false): void {
    localStorage.setItem(LocalStorageKey.actAsEmployerId, employer.id);

    if (reload) {
      // reload application for clean state
      window.location.reload();

      return;
    }

    CustomRouteReuseStrategy.scheduleClearCache();
    this.employer = employer;
    this.employer$.next(employer);
  }

  public getFullEmployer(employerId: string, silentLoading = false): Observable<ApiResponseEmployer> {
    const options = this.httpHelperService.getDefaultBypassLoadingOptions(silentLoading);

    return this.employerService.employerControllerGetFullEmployer0(employerId, undefined, undefined, options).pipe(
      retry(3),
      catchError((error) => {
        this.toastService.show(true, 'employer.loadingFailureMessage');

        return from(this.router.navigate([pageRoutes.CRITICAL_DATA_LOADING_FAILURE.path])).pipe(
          concatMap(() => {
            throw error;
          })
        );
      }),
      map((employer) => {
        this.employer$.next(employer);
        this.employer = employer;

        return employer;
      })
    );
  }

  // TODO: Remove as soon as we have an employer socket connection
  public async getFullEmployerUntilSubscriptionStatusChanges(
    currentSubscriptionStatus?: SubscriptionStatusEnum,
    maxRetries = 5,
    delayInSeconds = 2,
    currentRetry = 0
  ): Promise<ApiResponseEmployer | null> {
    if (currentRetry >= maxRetries) {
      return null;
    }

    const employer = await firstValueFrom(this.getFullEmployer(this.employer.id, true)).catch((error) => {
      throw error;
    });

    if (employer?.subscription?.status !== currentSubscriptionStatus) {
      return employer;
    }

    await new Promise((resolve) => {
      setTimeout(resolve, delayInSeconds * 1000);
    });

    return this.getFullEmployerUntilSubscriptionStatusChanges(
      currentSubscriptionStatus,
      maxRetries,
      delayInSeconds,
      currentRetry + 1
    );
  }

  public updateEmployer(apiRequestUpdateEmployer: ApiRequestUpdateEmployer): Observable<ApiResponseEmployer> {
    return this.employerService.employerControllerUpdateEmployer0(this.employerId, apiRequestUpdateEmployer).pipe(
      catchError((error) => {
        this.toastService.showDefaultErrorToast();
        throw error;
      }),
      tap((employer) => {
        this.actAsEmployer(employer, false);
      })
    );
  }

  public getEmployerConnectCode(): Observable<string> {
    return this.getEmployerStream().pipe(
      take(1),
      switchMap((employer) => {
        if (employer.connectCode) {
          return of(employer.connectCode!);
        }

        const connectCode = (Math.random() + 1).toString(36).substring(2);

        return this.updateEmployer({ ...employer!, connectCode }).pipe(
          map((updatedEmployer) => updatedEmployer.connectCode!)
        );
      })
    );
  }

  public changeLogo(file: File): Observable<string> {
    return this.employerService.employerControllerChangeLogo0(this.employerId, file).pipe(
      catchError((error) => {
        this.toastService.show(true, 'employer.edit.upsertError');
        throw error;
      })
    );
  }

  public getAllSimple(): Observable<ApiResponseSimpleEmployer[]> {
    return this.employerService.employerControllerGetAllSimpleEmployer0();
  }

  public getAllSimpleByPagination(
    page: number,
    pageSize: number,
    searchText?: string,
    sortField?: string,
    sortAscending?: boolean
  ): Observable<ApiResponseEmployerListWithPagination> {
    return this.employerService
      .employerControllerFindAllSimpleEmployerByPagination0({
        elementsPerBlock: pageSize,
        page,
        searchText,
        sortField,
        sortAscending,
      })
      .pipe(
        catchError((error) => {
          this.toastService.show(true, 'employer.getAllFailure');
          throw error;
        })
      );
  }

  public initEmployerDetailsMap(employers: EmployerDetails[]): Map<string, EmployerDetails[]> {
    const employersMap: Map<string, EmployerDetails[]> = new Map();

    const sortedEmployers = cloneDeep(employers).sort((a, b) => {
      if (a.name.toUpperCase() > b.name.toUpperCase()) {
        return 1;
      }

      return -1;
    });

    sortedEmployers.forEach((employer: EmployerDetails) => {
      const firstChar = employer.name.charAt(0).toUpperCase();

      if (employersMap.has(firstChar)) {
        employersMap.get(firstChar)!.push(employer);
      } else {
        employersMap.set(firstChar, [employer]);
      }
    });

    return employersMap;
  }

  public sortEmployersInMap(employersMap: Map<string, EmployerDetails[]>): void {
    employersMap.forEach((users) => {
      users.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }

        return -1;
      });
    });
  }

  public doesEmailDomainAlreadyExist(emailDomain: string): Observable<boolean> {
    return this.employerService.employerControllerCanDomainConnect0(emailDomain).pipe(
      catchError((error) => {
        this.toastService.show(true, 'employer.getAllFailure');
        throw error;
      })
    );
  }

  public canDomainConnect(domain: string): Observable<boolean> {
    return this.employerService.employerControllerCanDomainConnect0(domain).pipe(
      catchError((error) => {
        this.toastService.show(true, 'employer.getAllFailure');
        throw error;
      })
    );
  }

  public findSelectableEmployerByEmailDomainOfEmployer(
    domain: string
  ): Observable<ApiResponseSelectableForDashboardEmployer[]> {
    return this.employerService.employerControllerEmployerByConnectCodeAndDomainForDashboard0({ domain }).pipe(
      catchError((error) => {
        this.toastService.show(true, 'employer.getAllFailure');
        throw error;
      })
    );
  }

  public findSelectableEmployerByPrivateDomainOfConnectedUsers(
    domain: string
  ): Observable<ApiResponseSelectableForDashboardEmployer[]> {
    return this.employerService.employerControllerEmployerByPrivateDomain0(domain).pipe(
      catchError((error) => {
        this.toastService.show(true, 'employer.getAllFailure');
        throw error;
      })
    );
  }

  public getForUser(): Observable<ApiResponseEmployerAppModel> {
    return this.employerService.employerControllerGetForUser0().pipe(
      catchError((error) => {
        this.toastService.show(true, 'employer.loadingFailureMessage');
        throw error;
      })
    );
  }

  public findByFuzzySearch(name: string): Observable<ApiResponseSelectableEmployer[]> {
    return this.employerService.employerControllerFindSelectableByFuzzySearch0(name).pipe(
      catchError((error) => {
        this.toastService.show(true, 'employer.getAllFailure');
        throw error;
      })
    );
  }

  public findByAddressFuzzySearch(address: string): Observable<ApiResponseSelectableForDashboardEmployerWithAddress[]> {
    const options = this.httpHelperService.getDefaultBypassLoadingOptions(true);

    return this.employerService.employerControllerFindSelectableByAddressFuzzySearch0(
      address,
      undefined,
      undefined,
      options
    );

    // silent error as it's also a bypassed loading
  }
}
