/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestEmployerStatistic } from '../model/models';
import { ApiResponseCommuteList } from '../model/models';
import { ApiResponseModeDistribution } from '../model/models';
import { ApiResponseTrackedCommutesTimelineEntry } from '../model/models';
import { ApiResponseTripDistancePerModeInKilometers } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface CommuteStatisticsServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    tripStatisticsControllerFindTotalCommutes0(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    tripStatisticsControllerFindTotalCommutes1(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param employerId 
     * @param username 
     * @param from 
     * @param to 
     * @param page 
     * @param elementsPerBlock 
     */
    tripStatisticsControllerGetCommuteListByUsernameAndDatesWithPagination0(employerId: string, username: string, from: string, to: string, page: number, elementsPerBlock: number, extraHttpRequestParams?: any): Observable<ApiResponseCommuteList>;

    /**
     * 
     * 
     * @param employerId 
     * @param username 
     * @param from 
     * @param to 
     * @param page 
     * @param elementsPerBlock 
     */
    tripStatisticsControllerGetCommuteListByUsernameAndDatesWithPagination1(employerId: string, username: string, from: string, to: string, page: number, elementsPerBlock: number, extraHttpRequestParams?: any): Observable<ApiResponseCommuteList>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    tripStatisticsControllerGetModeDistributionByEmployer0(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<ApiResponseModeDistribution>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    tripStatisticsControllerGetModeDistributionByEmployer1(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<ApiResponseModeDistribution>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    tripStatisticsControllerGetTotalSavedCo2InGram0(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    tripStatisticsControllerGetTotalSavedCo2InGram1(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    tripStatisticsControllerGetTrackedCommutesTimeline0(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<Array<ApiResponseTrackedCommutesTimelineEntry>>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    tripStatisticsControllerGetTrackedCommutesTimeline1(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<Array<ApiResponseTrackedCommutesTimelineEntry>>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    tripStatisticsControllerGetTripDistanceInKilometers0(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    tripStatisticsControllerGetTripDistanceInKilometers1(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    tripStatisticsControllerGetTripDistanceInKilometersPerMode0(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<Array<ApiResponseTripDistancePerModeInKilometers>>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    tripStatisticsControllerGetTripDistanceInKilometersPerMode1(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<Array<ApiResponseTripDistancePerModeInKilometers>>;

}
