/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';



import { Configuration }                                     from '../configuration';



export interface S3StorageServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param url 
     */
    chatStorageControllerGetDeleteUrl0(url: string, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param url 
     */
    chatStorageControllerGetDeleteUrl1(url: string, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param fileName 
     */
    chatStorageControllerGetGetUrl0(fileName: string, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param fileName 
     */
    chatStorageControllerGetGetUrl1(fileName: string, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param fileName 
     * @param fileType 
     */
    chatStorageControllerGetPutUrl0(fileName: string, fileType: string, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param fileName 
     * @param fileType 
     */
    chatStorageControllerGetPutUrl1(fileName: string, fileType: string, extraHttpRequestParams?: any): Observable<string>;

}
