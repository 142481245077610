import { Injectable } from '@angular/core';
import { AuthenticationStatusService } from '@core/services/authentication-status/authentication-status.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, map } from 'rxjs';
import { StripeSubscriptionManagementService } from '../../../api';
import { EmployerWrapperService } from '../employer/employer-wrapper.service';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class StripeSubscriptionManagementWrapperService {
  private checkoutUrl?: string;

  private customerPortalUrl?: string;

  constructor(
    private readonly stripeSubscriptionManagementWrapperService: StripeSubscriptionManagementService,
    private readonly employerWrapperService: EmployerWrapperService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly authStatusService: AuthenticationStatusService
  ) {
    // preload the checkout url and customer portal
    if (this.employerWrapperService.employer.customer && this.authStatusService.isEmailVerified()) {
      this.loadCheckoutUrl();
      this.loadCustomerPortalUrl();
    }
  }

  private loadCheckoutUrl(): void {
    if (this.checkoutUrl) {
      return;
    }

    this.startCheckoutSession().subscribe({
      next: (url) => {
        this.checkoutUrl = url;
      },
    });
  }

  private loadCustomerPortalUrl(): void {
    if (this.customerPortalUrl) {
      return;
    }

    this.startCustomerPortalSession().subscribe({
      next: (url) => {
        this.customerPortalUrl = url;
      },
    });
  }

  private startCheckoutSession(): Observable<string> {
    return this.stripeSubscriptionManagementWrapperService
      .stripeSubscriptionManagementControllerGetCheckoutUrl0(this.employerWrapperService.employerId)
      .pipe(
        catchError((error) => {
          this.toastService.show(true, 'organization.billing.failure');
          throw error;
        }),
        map((result) => result)
      );
  }

  private startCustomerPortalSession(): Observable<string> {
    return this.stripeSubscriptionManagementWrapperService
      .stripeSubscriptionManagementControllerGetCustomerPortal0(this.employerWrapperService.employerId)
      .pipe(
        catchError((error) => {
          this.toastService.show(true, 'organization.billing.failure');
          throw error;
        }),
        map((result) => result)
      );
  }

  public openCheckoutUrl(): void {
    if (!this.checkoutUrl) {
      this.toastService.show(true, this.translateService.instant('general.linkOpenError'));
      this.loadCheckoutUrl();

      return;
    }

    window.open(this.checkoutUrl, '_blank');
    this.addTabVisibilityChangeListener();
  }

  public openCustomerPortal(): void {
    if (!this.customerPortalUrl) {
      this.toastService.show(true, this.translateService.instant('general.linkOpenError'));
      this.loadCustomerPortalUrl();

      return;
    }

    window.open(this.customerPortalUrl, '_blank');
    this.addTabVisibilityChangeListener();
  }

  private addTabVisibilityChangeListener(): void {
    const visibilityChangeHandler = (): void => {
      if (document.visibilityState === 'visible') {
        this.employerWrapperService.getFullEmployer(this.employerWrapperService.employerId).subscribe();

        document.removeEventListener('visibilitychange', visibilityChangeHandler);
      }
    };

    document.addEventListener('visibilitychange', visibilityChangeHandler);
  }
}
