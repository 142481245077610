/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestDeleteTrips } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface TripDeleteServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param apiRequestDeleteTrips 
     */
    tripDeleteControllerAdminDeleteMany0(apiRequestDeleteTrips: ApiRequestDeleteTrips, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestDeleteTrips 
     */
    tripDeleteControllerAdminDeleteMany1(apiRequestDeleteTrips: ApiRequestDeleteTrips, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestDeleteTrips 
     */
    tripDeleteControllerDeleteMany0(apiRequestDeleteTrips: ApiRequestDeleteTrips, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestDeleteTrips 
     */
    tripDeleteControllerDeleteMany1(apiRequestDeleteTrips: ApiRequestDeleteTrips, extraHttpRequestParams?: any): Observable<{}>;

}
