/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestEmployerStatistic } from '../model/models';
import { ApiResponseTangoOrders } from '../model/models';
import { ApiResponseTangoSummary } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface EmployerStatisticsServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    employerStatisticsControllerFindTotalMessagesSent0(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    employerStatisticsControllerFindTotalMessagesSent1(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    employerStatisticsControllerGetCurrentCoinBalance0(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    employerStatisticsControllerGetCurrentCoinBalance1(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param employerId 
     * @param page 
     */
    employerStatisticsControllerGetTangoOrders0(employerId: string, page: number, extraHttpRequestParams?: any): Observable<ApiResponseTangoOrders>;

    /**
     * 
     * 
     * @param employerId 
     * @param page 
     */
    employerStatisticsControllerGetTangoOrders1(employerId: string, page: number, extraHttpRequestParams?: any): Observable<ApiResponseTangoOrders>;

    /**
     * 
     * 
     * @param employerCode 
     */
    employerStatisticsControllerGetTangoSummary0(employerCode: string, extraHttpRequestParams?: any): Observable<ApiResponseTangoSummary>;

    /**
     * 
     * 
     * @param employerCode 
     */
    employerStatisticsControllerGetTangoSummary1(employerCode: string, extraHttpRequestParams?: any): Observable<ApiResponseTangoSummary>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    employerStatisticsControllerGetTotalRedeemedCoins0(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    employerStatisticsControllerGetTotalRedeemedCoins1(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<number>;

}
