/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestSilentPushPayload } from '../model/models';
import { ApiRequestTestPush } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface PushNotificationServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param apiRequestTestPush 
     */
    pushNotificationControllerSend0(apiRequestTestPush: ApiRequestTestPush, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param apiRequestTestPush 
     */
    pushNotificationControllerSend1(apiRequestTestPush: ApiRequestTestPush, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param token 
     * @param apiRequestSilentPushPayload 
     */
    pushNotificationControllerSendSilent0(token: string, apiRequestSilentPushPayload: ApiRequestSilentPushPayload, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param token 
     * @param apiRequestSilentPushPayload 
     */
    pushNotificationControllerSendSilent1(token: string, apiRequestSilentPushPayload: ApiRequestSilentPushPayload, extraHttpRequestParams?: any): Observable<string>;

}
