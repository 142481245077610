/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiResponsePurchasesWithPagination } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface RewardsUserServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param page 
     * @param elementsPerBlock 
     */
    rewardsUserControllerGetPurchasesWithPagination0(page: number, elementsPerBlock: number, extraHttpRequestParams?: any): Observable<ApiResponsePurchasesWithPagination>;

    /**
     * 
     * 
     * @param page 
     * @param elementsPerBlock 
     */
    rewardsUserControllerGetPurchasesWithPagination1(page: number, elementsPerBlock: number, extraHttpRequestParams?: any): Observable<ApiResponsePurchasesWithPagination>;

}
