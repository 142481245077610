import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationStatusService } from '@core/services/authentication-status/authentication-status.service';
import { AuthenticationWrapperService } from '@core/services/authentication/authentication-wrapper.service';
import { GoogleAnalyticsService } from '@core/services/google-analytics/google-analytics.service';
import { MailerooWrapperService } from '@core/services/maileroo-wrapper.service';
import { ToastService } from '@core/services/toast/toast.service';
import { UserDataService } from '@core/services/user/user-data.service';
import { UserWrapperService } from '@core/services/user/user-wrapper.service';
import { TranslateModule } from '@ngx-translate/core';
import { DialogId } from '@shared/enums/dialog-id.enum';
import { MaterialModule } from '@shared/modules/material/material.module';
import { companyEmailValidator } from '@shared/validators/company-email-verification.validator';
import { emailExistsValidator } from '@shared/validators/email-exists.validator';
import { emailVerificationValidator, ExtendedControl } from '@shared/validators/email-verification.validator';
import { take } from 'rxjs';
import { DomainSuggestionComponent } from 'src/app/pre-login/register/components/domain-suggestion/domain-suggestion.component';

export interface AuthEmailVerificationUpdateEmailDialogData {
  email: string;
}

export interface EmailForm {
  email: FormControl<string | null>;
}

@Component({
  selector: 'auth-email-verification-update-email-dialog',
  standalone: true,
  imports: [TranslateModule, MaterialModule, CommonModule, DomainSuggestionComponent],
  templateUrl: './auth-email-verification-update-email-dialog.component.html',
  styleUrls: ['./auth-email-verification-update-email-dialog.component.scss'],
})
export class AuthEmailVerificationUpdateEmailDialogComponent {
  public isAuthenticated: boolean;

  public formGroup!: FormGroup<EmailForm>;

  constructor(
    public readonly dialogRef: DialogRef<void>,
    private readonly authWrapperService: AuthenticationWrapperService,
    private readonly authStatusService: AuthenticationStatusService,
    private readonly toastService: ToastService,
    private readonly formBuilder: FormBuilder,
    private readonly mailerooService: MailerooWrapperService,
    private readonly userWrapperService: UserWrapperService,
    private readonly userDataService: UserDataService,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.initForm();
    this.isAuthenticated = this.authStatusService.isAuthenticated();
  }

  public onClose(): void {
    this.closeDialog();
  }

  public onUpdateEmail(): void {
    if (this.formGroup.valid) {
      this.changeEmail();
    }
  }

  private changeEmail(): void {
    // if the email is the same and the user clicks on "change", we just close the dialog as we don't need to do any calls
    if (this.formGroup.controls.email.value! === this.userDataService.user.email) {
      this.dialogRef.close();

      return;
    }

    this.authWrapperService
      .changeEmail(this.formGroup.controls.email.value!)
      .pipe(take(1))
      .subscribe(() => {
        const isError = false;

        this.toastService.show(isError, 'verifyEmail.email.sent');

        this.authStatusService.refresh().pipe(take(1)).subscribe();

        this.dialogRef.close();

        window.location.reload();
      });
  }

  private closeDialog(): void {
    this.dialogRef.close();
  }

  private initForm(): void {
    this.formGroup = this.formBuilder.group<EmailForm>({
      email: this.formBuilder.control(this.authStatusService.getEmailFromIdToken(), {
        validators: [Validators.required, Validators.email, companyEmailValidator(this.googleAnalyticsService)],
        asyncValidators: [
          emailVerificationValidator(this.mailerooService),
          emailExistsValidator(this.userWrapperService, this.userDataService.user.email),
        ],
      }),
    });
  }

  public static open(dialog: Dialog, data: unknown): DialogRef<void, unknown> {
    const config = {
      id: DialogId.AUTH_EMAIL_VERIFICATION_UPDATE_EMAIL_DIALOG,
      data,
    };

    return dialog.open<void>(AuthEmailVerificationUpdateEmailDialogComponent, config);
  }

  public get emailDomainSuggestionWarning(): string {
    return (this.formGroup.controls.email as ExtendedControl).warnings?.domainSuggestion;
  }

  public onSuggestionUse(correctedEmail: string): void {
    this.formGroup.controls.email.patchValue(correctedEmail);
    this.formGroup.controls.email.updateValueAndValidity();
  }
}
