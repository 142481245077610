/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiResponseFullStatisticForUserCo2SavedByModeInKg { 
    car?: number;
    bike?: number;
    walk?: number;
    scooter?: number;
    transit?: number;
    carpool?: number;
    internalShuttle?: number;
}

