import { Injectable } from '@angular/core';
import { HttpHelperService } from '@core/services/http-helper.service';
import { MixpanelEvent } from '@core/services/mixpanel/mixpanel-event.enum';
import { SentryService } from '@core/services/sentry.service';
import { MixpanelService } from '@swagger/index';
import { catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MixpanelWrapperService {
  constructor(
    private readonly mixpanelService: MixpanelService,
    private readonly httpHelperService: HttpHelperService,
    private readonly sentryService: SentryService
  ) {}

  public track(event: MixpanelEvent, properties?: Record<string, unknown>): void {
    const options = this.httpHelperService.getDefaultBypassLoadingOptions(true);

    this.mixpanelService
      .mixpanelControllerCreate0(
        {
          eventName: event,
          properties,
        },
        'body',
        false,
        options
      )
      .pipe(
        catchError((error) => {
          this.sentryService.captureException(error);

          return of(null);
        })
      )
      .subscribe();
  }
}
