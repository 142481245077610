/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TripModeEnum } from './tripModeEnum';


export interface ChildCommuteLimitedDetails { 
    mainMode: TripModeEnum | null;
    id: string;
    username: string;
    isRewarded: boolean;
    type: ChildCommuteLimitedDetailsTypeEnum;
    origin: ChildCommuteLimitedDetailsOriginEnum;
    startTime: string;
    endTime: string;
    coins: number;
}
export enum ChildCommuteLimitedDetailsTypeEnum {
    TO_POI = 'TO_POI',
    THROUGH_CORRIDOR = 'THROUGH_CORRIDOR'
};
export enum ChildCommuteLimitedDetailsOriginEnum {
    SENTIANCE = 'SENTIANCE',
    GPS_TRACKING_PAVE = 'GPS_TRACKING_PAVE',
    SELF_REPORTED = 'SELF_REPORTED'
};



