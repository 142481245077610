import { Injectable } from '@angular/core';
import { LocalStorageKey } from '@shared/enums/local-storage-key.enum';
import { TimeFrameOption } from '@shared/enums/time-frame-option.enum';
import { TimeFrameItem } from '@shared/interfaces/time-frame-item.interface';
import { TimeFrame } from '@shared/interfaces/time-frame.interface';
import { DateHelperService } from '../date-helper/date-helper.service';
import { EmployerWrapperService } from '../employer/employer-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class TimeFrameItemHelperService {
  public employerCreatedAtDate: Date;

  constructor(
    private readonly dateHelperService: DateHelperService,
    private readonly employerWrapperService: EmployerWrapperService
  ) {
    this.employerCreatedAtDate = new Date(new Date(this.employerWrapperService.employer.createdAt).setHours(0, 0, 0));
  }

  public getTimeFrameItemFromLocalStorage(key: LocalStorageKey, fallback?: () => TimeFrameItem): TimeFrameItem {
    const savedTimeFrameStorageItem = localStorage.getItem(key);

    if (!savedTimeFrameStorageItem) {
      if (fallback) {
        return fallback();
      }

      return this.getDefaultTimeFrameItem();
    }

    try {
      return this.initTimeFrameItemFromLocalStorage(JSON.parse(savedTimeFrameStorageItem) as TimeFrameItem);
    } catch (_e) {
      return this.getDefaultTimeFrameItem();
    }
  }

  private initTimeFrameItemFromLocalStorage(timeFrameItem: TimeFrameItem): TimeFrameItem {
    switch (timeFrameItem.option) {
      case TimeFrameOption.individual:
        return this.getIndividualTimeFrameItem(timeFrameItem.timeFrame);

      case TimeFrameOption.allTime:
        return this.getAllTimeTimeFrameItem();

      case TimeFrameOption.thisMonth:
        return this.getThisMonthTimeFrameItem();

      case TimeFrameOption.thisYear:
        return this.getThisYearTimeFrameItem();

      case TimeFrameOption.lastTwelveMonth:
        return this.getLast12MonthsTimeFrameItem();

      default:
        return this.getLast12MonthsTimeFrameItem();
    }
  }

  public getDefaultTimeFrameItem(): TimeFrameItem {
    return this.getLast12MonthsTimeFrameItem();
  }

  public getAllTimeTimeFrameItem(): TimeFrameItem {
    const from = this.employerCreatedAtDate;
    const to = this.dateHelperService.getEndOfToday();

    return {
      timeFrame: {
        from,
        to,
      },
      option: TimeFrameOption.allTime,
    };
  }

  public getLast12MonthsTimeFrameItem(): TimeFrameItem {
    const oneYearBackDate = this.dateHelperService.getOneYearBackDate();

    const from = this.dateHelperService.isBeforeDate(oneYearBackDate, this.employerCreatedAtDate)
      ? this.employerCreatedAtDate
      : oneYearBackDate;
    const to = this.dateHelperService.getEndOfToday();

    return {
      timeFrame: {
        from,
        to,
      },
      option: TimeFrameOption.lastTwelveMonth,
    };
  }

  public getThisYearTimeFrameItem(): TimeFrameItem {
    const startOfYearDate = this.dateHelperService.getStartOfYear();

    const from = this.dateHelperService.isBeforeDate(startOfYearDate, this.employerCreatedAtDate)
      ? this.employerCreatedAtDate
      : startOfYearDate;
    const to = this.dateHelperService.getEndOfToday();

    return {
      timeFrame: {
        from,
        to,
      },
      option: TimeFrameOption.thisYear,
    };
  }

  public getThisMonthTimeFrameItem(): TimeFrameItem {
    const startOfMonthDate = this.dateHelperService.getStartOfMonth();

    const from = this.dateHelperService.isBeforeDate(startOfMonthDate, this.employerCreatedAtDate)
      ? this.employerCreatedAtDate
      : startOfMonthDate;
    const to = this.dateHelperService.getEndOfToday();

    return {
      timeFrame: {
        from,
        to,
      },
      option: TimeFrameOption.thisMonth,
    };
  }

  public getIndividualTimeFrameItem(timeFrame: TimeFrame): TimeFrameItem {
    const fromDate = new Date(timeFrame.from);

    const from = this.dateHelperService.isBeforeDate(fromDate, this.employerCreatedAtDate)
      ? this.employerCreatedAtDate
      : fromDate;

    const to = new Date(timeFrame.to);

    return {
      timeFrame: {
        from,
        to,
      },
      option: TimeFrameOption.individual,
    };
  }
}
