/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestFindAllSimpleByPagination } from '../model/models';
import { ApiRequestFindEmployerByCodeAndDomain } from '../model/models';
import { ApiRequestUpdateEmployer } from '../model/models';
import { ApiResponseEmployer } from '../model/models';
import { ApiResponseEmployerAppModel } from '../model/models';
import { ApiResponseEmployerListWithPagination } from '../model/models';
import { ApiResponseManagerOnboardingAppStatus } from '../model/models';
import { ApiResponseSelectableEmployer } from '../model/models';
import { ApiResponseSelectableForDashboardEmployer } from '../model/models';
import { ApiResponseSelectableForDashboardEmployerWithAddress } from '../model/models';
import { ApiResponseSimpleEmployer } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface EmployerServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param domain 
     */
    employerControllerCanDomainConnect0(domain: string, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param domain 
     */
    employerControllerCanDomainConnect1(domain: string, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param employerId 
     * @param file 
     */
    employerControllerChangeLogo0(employerId: string, file: Blob, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param employerId 
     * @param file 
     */
    employerControllerChangeLogo1(employerId: string, file: Blob, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param apiRequestFindEmployerByCodeAndDomain 
     */
    employerControllerEmployerByConnectCodeAndDomainForDashboard0(apiRequestFindEmployerByCodeAndDomain: ApiRequestFindEmployerByCodeAndDomain, extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableForDashboardEmployer>>;

    /**
     * 
     * 
     * @param apiRequestFindEmployerByCodeAndDomain 
     */
    employerControllerEmployerByConnectCodeAndDomainForDashboard1(apiRequestFindEmployerByCodeAndDomain: ApiRequestFindEmployerByCodeAndDomain, extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableForDashboardEmployer>>;

    /**
     * 
     * 
     * @param apiRequestFindEmployerByCodeAndDomain 
     */
    employerControllerEmployerByConnectCodeAndDomainPost0(apiRequestFindEmployerByCodeAndDomain: ApiRequestFindEmployerByCodeAndDomain, extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableEmployer>>;

    /**
     * 
     * 
     * @param apiRequestFindEmployerByCodeAndDomain 
     */
    employerControllerEmployerByConnectCodeAndDomainPost1(apiRequestFindEmployerByCodeAndDomain: ApiRequestFindEmployerByCodeAndDomain, extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableEmployer>>;

    /**
     * 
     * 
     * @param domain 
     */
    employerControllerEmployerByPrivateDomain0(domain: string, extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableForDashboardEmployer>>;

    /**
     * 
     * 
     * @param domain 
     */
    employerControllerEmployerByPrivateDomain1(domain: string, extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableForDashboardEmployer>>;

    /**
     * 
     * 
     * @param apiRequestFindAllSimpleByPagination 
     */
    employerControllerFindAllSimpleEmployerByPagination0(apiRequestFindAllSimpleByPagination: ApiRequestFindAllSimpleByPagination, extraHttpRequestParams?: any): Observable<ApiResponseEmployerListWithPagination>;

    /**
     * 
     * 
     * @param apiRequestFindAllSimpleByPagination 
     */
    employerControllerFindAllSimpleEmployerByPagination1(apiRequestFindAllSimpleByPagination: ApiRequestFindAllSimpleByPagination, extraHttpRequestParams?: any): Observable<ApiResponseEmployerListWithPagination>;

    /**
     * 
     * 
     * @param addressSearchText 
     */
    employerControllerFindSelectableByAddressFuzzySearch0(addressSearchText: string, extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableForDashboardEmployerWithAddress>>;

    /**
     * 
     * 
     * @param addressSearchText 
     */
    employerControllerFindSelectableByAddressFuzzySearch1(addressSearchText: string, extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableForDashboardEmployerWithAddress>>;

    /**
     * 
     * 
     * @param searchText 
     */
    employerControllerFindSelectableByFuzzySearch0(searchText: string, extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableForDashboardEmployer>>;

    /**
     * 
     * 
     * @param searchText 
     */
    employerControllerFindSelectableByFuzzySearch1(searchText: string, extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableForDashboardEmployer>>;

    /**
     * 
     * 
     */
    employerControllerGetAllSimpleEmployer0(extraHttpRequestParams?: any): Observable<Array<ApiResponseSimpleEmployer>>;

    /**
     * 
     * 
     */
    employerControllerGetAllSimpleEmployer1(extraHttpRequestParams?: any): Observable<Array<ApiResponseSimpleEmployer>>;

    /**
     * 
     * 
     */
    employerControllerGetForUser0(extraHttpRequestParams?: any): Observable<ApiResponseEmployerAppModel>;

    /**
     * 
     * 
     */
    employerControllerGetForUser1(extraHttpRequestParams?: any): Observable<ApiResponseEmployerAppModel>;

    /**
     * 
     * 
     */
    employerControllerGetForUser2(extraHttpRequestParams?: any): Observable<ApiResponseEmployerAppModel>;

    /**
     * 
     * 
     */
    employerControllerGetForUser3(extraHttpRequestParams?: any): Observable<ApiResponseEmployerAppModel>;

    /**
     * 
     * 
     * @param employerCode 
     */
    employerControllerGetFullEmployer0(employerCode: string, extraHttpRequestParams?: any): Observable<ApiResponseEmployer>;

    /**
     * 
     * 
     * @param employerCode 
     */
    employerControllerGetFullEmployer1(employerCode: string, extraHttpRequestParams?: any): Observable<ApiResponseEmployer>;

    /**
     * 
     * 
     * @param employerId 
     */
    employerControllerGetManagerOnboardingAppStatus0(employerId: string, extraHttpRequestParams?: any): Observable<ApiResponseManagerOnboardingAppStatus>;

    /**
     * 
     * 
     * @param employerId 
     */
    employerControllerGetManagerOnboardingAppStatus1(employerId: string, extraHttpRequestParams?: any): Observable<ApiResponseManagerOnboardingAppStatus>;

    /**
     * 
     * 
     */
    employerControllerList0(extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableEmployer>>;

    /**
     * 
     * 
     */
    employerControllerList1(extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableEmployer>>;

    /**
     * 
     * 
     * @param employerId 
     * @param apiRequestUpdateEmployer 
     */
    employerControllerUpdateEmployer0(employerId: string, apiRequestUpdateEmployer: ApiRequestUpdateEmployer, extraHttpRequestParams?: any): Observable<ApiResponseEmployer>;

    /**
     * 
     * 
     * @param employerId 
     * @param apiRequestUpdateEmployer 
     */
    employerControllerUpdateEmployer1(employerId: string, apiRequestUpdateEmployer: ApiRequestUpdateEmployer, extraHttpRequestParams?: any): Observable<ApiResponseEmployer>;

    /**
     * 
     * 
     * @param employerId 
     * @param apiRequestUpdateEmployer 
     */
    employerControllerUpdateEmployer2(employerId: string, apiRequestUpdateEmployer: ApiRequestUpdateEmployer, extraHttpRequestParams?: any): Observable<ApiResponseEmployer>;

    /**
     * 
     * 
     * @param employerId 
     * @param apiRequestUpdateEmployer 
     */
    employerControllerUpdateEmployer3(employerId: string, apiRequestUpdateEmployer: ApiRequestUpdateEmployer, extraHttpRequestParams?: any): Observable<ApiResponseEmployer>;

}
