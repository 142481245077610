/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestDeleteEmployerWorkAddress } from '../model/models';
import { ApiRequestDeleteTrialRequest } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface EmployerDeleteServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param employerId 
     */
    employerDeleteControllerDelete0(employerId: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param employerId 
     */
    employerDeleteControllerDelete1(employerId: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestDeleteEmployerWorkAddress 
     */
    employerDeleteControllerDeleteWorkAddress0(apiRequestDeleteEmployerWorkAddress: ApiRequestDeleteEmployerWorkAddress, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestDeleteEmployerWorkAddress 
     */
    employerDeleteControllerDeleteWorkAddress1(apiRequestDeleteEmployerWorkAddress: ApiRequestDeleteEmployerWorkAddress, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestDeleteTrialRequest 
     */
    employerDeleteControllerRequestTrialDeletion0(apiRequestDeleteTrialRequest: ApiRequestDeleteTrialRequest, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestDeleteTrialRequest 
     */
    employerDeleteControllerRequestTrialDeletion1(apiRequestDeleteTrialRequest: ApiRequestDeleteTrialRequest, extraHttpRequestParams?: any): Observable<{}>;

}
