import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { MailerooWrapperService } from '@core/services/maileroo-wrapper.service';
import { ApiResponseMaileroo } from '@swagger/index';
import { Observable, of } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';

export interface ExtendedControl extends AbstractControl {
  warnings?: ValidationWarnings | null;
}

export declare type ValidationWarnings = {
  [key: string]: any;
};

export function emailVerificationValidator(
  mailerooService: MailerooWrapperService,
  ignoreDisposableEmails = false
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const email = control.value;
    const extendedControl = control as ExtendedControl;

    if (!email) {
      extendedControl.warnings = null;

      return of(null);
    }

    return of(email).pipe(
      delay(300),
      switchMap((value) =>
        mailerooService.verifyEmailAddress(value).pipe(
          map((response: ApiResponseMaileroo) => {
            const errors: ValidationErrors = {};
            const warnings: ValidationWarnings = {};

            const isValid = response.success && response.data?.format_valid && response.data.mx_found;
            const isDisposable = ignoreDisposableEmails ? false : response.data?.disposable;
            const domainSuggestion = response.data?.domain_suggestion;

            if (!isValid) {
              errors.email = true;
            }

            if (isDisposable) {
              errors.disposable = true;
            }

            if (domainSuggestion) {
              if (isValid) {
                warnings.domainSuggestion = domainSuggestion;
              } else {
                errors.domainSuggestion = domainSuggestion;
              }
            }

            extendedControl.warnings = warnings;

            return Object.keys(errors).length ? errors : null;
          }),
          catchError(() => {
            extendedControl.warnings = null;

            return of(null);
          }) // silent fail if something goes wrong
        )
      )
    );
  };
}
