/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestAddUserByEmailModel } from '../model/models';
import { ApiRequestConnectToEmployerModel } from '../model/models';
import { ApiRequestConnectUserModel } from '../model/models';
import { ApiResponseSuccessModel } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface EmployerConnectServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param apiRequestAddUserByEmailModel 
     */
    employerConnectControllerAddUserWithEmail0(apiRequestAddUserByEmailModel: ApiRequestAddUserByEmailModel, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestAddUserByEmailModel 
     */
    employerConnectControllerAddUserWithEmail1(apiRequestAddUserByEmailModel: ApiRequestAddUserByEmailModel, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestConnectToEmployerModel 
     */
    employerConnectControllerConnectToPublic0(apiRequestConnectToEmployerModel: ApiRequestConnectToEmployerModel, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param apiRequestConnectToEmployerModel 
     */
    employerConnectControllerConnectToPublic1(apiRequestConnectToEmployerModel: ApiRequestConnectToEmployerModel, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param apiRequestConnectUserModel 
     */
    employerConnectControllerConnectUserRequest0(apiRequestConnectUserModel: ApiRequestConnectUserModel, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param apiRequestConnectUserModel 
     */
    employerConnectControllerConnectUserRequest1(apiRequestConnectUserModel: ApiRequestConnectUserModel, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     */
    employerConnectControllerConnectedUserStatus0(extraHttpRequestParams?: any): Observable<object>;

    /**
     * 
     * 
     */
    employerConnectControllerConnectedUserStatus1(extraHttpRequestParams?: any): Observable<object>;

    /**
     * 
     * 
     */
    employerConnectControllerDisconnectMe0(extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     */
    employerConnectControllerDisconnectMe1(extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param employerId 
     */
    employerConnectControllerJoinCompanyViaDashboard0(employerId: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param employerId 
     */
    employerConnectControllerJoinCompanyViaDashboard1(employerId: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestConnectToEmployerModel 
     */
    employerConnectControllerSelfConnect0(apiRequestConnectToEmployerModel: ApiRequestConnectToEmployerModel, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestConnectToEmployerModel 
     */
    employerConnectControllerSelfConnect1(apiRequestConnectToEmployerModel: ApiRequestConnectToEmployerModel, extraHttpRequestParams?: any): Observable<{}>;

}
