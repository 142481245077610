/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestMarketingCloudSyncUserModel } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface MarketingCloudServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     */
    marketingCloudControllerSyncAll0(extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     */
    marketingCloudControllerSyncAll1(extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     */
    marketingCloudControllerSyncEmployers0(extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     */
    marketingCloudControllerSyncEmployers1(extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestMarketingCloudSyncUserModel 
     */
    marketingCloudControllerSyncUser0(apiRequestMarketingCloudSyncUserModel: ApiRequestMarketingCloudSyncUserModel, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestMarketingCloudSyncUserModel 
     */
    marketingCloudControllerSyncUser1(apiRequestMarketingCloudSyncUserModel: ApiRequestMarketingCloudSyncUserModel, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     */
    marketingCloudControllerSyncUsers0(extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     */
    marketingCloudControllerSyncUsers1(extraHttpRequestParams?: any): Observable<{}>;

}
