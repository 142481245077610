import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  ApiRequestUpdateBaseIncentiveNetwork,
  ApiResponseBaseIncentiveNetwork,
  IncentiveNetworkService,
} from 'src/app/api';

import { EmployerWrapperService } from '../employer/employer-wrapper.service';
import { HttpHelperService } from '../http-helper.service';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class IncentiveNetworksWrapperService {
  private baseIncentiveNetwork$: ReplaySubject<ApiResponseBaseIncentiveNetwork> = new ReplaySubject(1);

  constructor(
    private readonly incentiveNetworkService: IncentiveNetworkService,
    private readonly toastService: ToastService,
    private readonly employerWrapperService: EmployerWrapperService,
    private readonly httpHelperService: HttpHelperService
  ) {}

  public getBaseIncentiveNetworkStream(): Observable<ApiResponseBaseIncentiveNetwork> {
    return this.baseIncentiveNetwork$.asObservable();
  }

  public getBaseIncentiveNetworksByEmployer(
    employerId?: string,
    silentLoading = false
  ): Observable<ApiResponseBaseIncentiveNetwork> {
    let currentEmployerId = employerId;

    if (!currentEmployerId) {
      currentEmployerId = this.employerWrapperService.employerId;
    }

    const options = this.httpHelperService.getDefaultBypassLoadingOptions(silentLoading);

    return this.incentiveNetworkService
      .incentiveNetworkControllerGetBaseIncentiveNetworksByEmployer0(currentEmployerId, undefined, undefined, options)
      .pipe(
        catchError((error) => {
          this.toastService.show(true, 'incentiveNetworks.baseNetworkLoadError');
          throw error;
        }),
        map((res) => {
          this.baseIncentiveNetwork$.next(res);

          return res;
        })
      );
  }

  public updateBaseIncentiveNetwork(
    network: ApiResponseBaseIncentiveNetwork,
    employerId?: string
  ): Observable<ApiResponseBaseIncentiveNetwork> {
    let employerIdToUpdate = employerId;

    if (!employerIdToUpdate) {
      employerIdToUpdate = this.employerWrapperService.employerId;
    }

    const request: ApiRequestUpdateBaseIncentiveNetwork = {
      tangoCustomerId: network.tangoCustomerId,
      pointToMoneyConversionFactor: network.pointToMoneyConversionFactor,
      rewardStyles: network.rewardStyles,
      employerId: employerIdToUpdate,
    };

    return this.incentiveNetworkService.incentiveNetworkControllerUpdateBaseIncentiveNetwork0(request);
  }
}
