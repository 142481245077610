/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Currency } from './currency';
import { ChildUserIncentiveModel } from './childUserIncentiveModel';
import { UnitSystem } from './unitSystem';


export interface ChildInternalPropertiesModel { 
    unitSystem: UnitSystem | null;
    currency: Currency | null;
    onboardingCompletedDate: string | null;
    registeredDate: string;
    utcOffset: number;
    sentianceID: string | null;
    deviceId: string;
    locale: string;
    groupsOnboardingCompletedAt?: string;
    incentive: ChildUserIncentiveModel;
}



