/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestCommentOnFeedEntry } from '../model/models';
import { ApiRequestReactToFeedEntry } from '../model/models';
import { ApiResponseSocialFeed } from '../model/models';
import { ChildSocialFeedEntry } from '../model/models';
import { SocialFeedDiscriminator } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface SocialFeedServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param apiRequestCommentOnFeedEntry 
     */
    socialFeedControllerCommentOnFeedEntry0(apiRequestCommentOnFeedEntry: ApiRequestCommentOnFeedEntry, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestCommentOnFeedEntry 
     */
    socialFeedControllerCommentOnFeedEntry1(apiRequestCommentOnFeedEntry: ApiRequestCommentOnFeedEntry, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param page 
     * @param elementsPerBlock 
     */
    socialFeedControllerGetFeed0(page: number, elementsPerBlock: number, extraHttpRequestParams?: any): Observable<ApiResponseSocialFeed>;

    /**
     * 
     * 
     * @param page 
     * @param elementsPerBlock 
     */
    socialFeedControllerGetFeed1(page: number, elementsPerBlock: number, extraHttpRequestParams?: any): Observable<ApiResponseSocialFeed>;

    /**
     * 
     * 
     * @param discriminator 
     * @param entryId 
     */
    socialFeedControllerGetFeedEntryById0(discriminator: SocialFeedDiscriminator, entryId: string, extraHttpRequestParams?: any): Observable<ChildSocialFeedEntry>;

    /**
     * 
     * 
     * @param discriminator 
     * @param entryId 
     */
    socialFeedControllerGetFeedEntryById1(discriminator: SocialFeedDiscriminator, entryId: string, extraHttpRequestParams?: any): Observable<ChildSocialFeedEntry>;

    /**
     * 
     * 
     * @param apiRequestReactToFeedEntry 
     */
    socialFeedControllerReactToFeedEntry0(apiRequestReactToFeedEntry: ApiRequestReactToFeedEntry, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestReactToFeedEntry 
     */
    socialFeedControllerReactToFeedEntry1(apiRequestReactToFeedEntry: ApiRequestReactToFeedEntry, extraHttpRequestParams?: any): Observable<{}>;

}
