/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestManuallyReward } from '../model/models';
import { ApiRequestPointHistoryWithPagination } from '../model/models';
import { ApiRequestRedeemModel } from '../model/models';
import { ApiRequestResetAccountBalance } from '../model/models';
import { ApiRequestUserReferredModel } from '../model/models';
import { ApiResponseCSVReward } from '../model/models';
import { ApiResponsePointHistoryWithPagination } from '../model/models';
import { ApiResponseRedeemModel } from '../model/models';
import { ApiResponseSuccessModel } from '../model/models';
import { ApiResponseUserAccountBalanceModel } from '../model/models';
import { ApiResponseUserTransactionsExport } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface RewardsServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     */
    rewardsControllerGetAccountBalance0(extraHttpRequestParams?: any): Observable<ApiResponseUserAccountBalanceModel>;

    /**
     * 
     * 
     */
    rewardsControllerGetAccountBalance1(extraHttpRequestParams?: any): Observable<ApiResponseUserAccountBalanceModel>;

    /**
     * 
     * 
     * @param apiRequestPointHistoryWithPagination 
     */
    rewardsControllerGetPointsHistoryWithPagination0(apiRequestPointHistoryWithPagination: ApiRequestPointHistoryWithPagination, extraHttpRequestParams?: any): Observable<ApiResponsePointHistoryWithPagination>;

    /**
     * 
     * 
     * @param apiRequestPointHistoryWithPagination 
     */
    rewardsControllerGetPointsHistoryWithPagination1(apiRequestPointHistoryWithPagination: ApiRequestPointHistoryWithPagination, extraHttpRequestParams?: any): Observable<ApiResponsePointHistoryWithPagination>;

    /**
     * 
     * 
     * @param username 
     */
    rewardsControllerGetSpecificUserAccountBalance0(username: string, extraHttpRequestParams?: any): Observable<ApiResponseUserAccountBalanceModel>;

    /**
     * 
     * 
     * @param username 
     */
    rewardsControllerGetSpecificUserAccountBalance1(username: string, extraHttpRequestParams?: any): Observable<ApiResponseUserAccountBalanceModel>;

    /**
     * 
     * 
     * @param employerId 
     * @param rewardStyleConfigId 
     * @param from 
     * @param to 
     */
    rewardsControllerGetTransactions0(employerId: string, rewardStyleConfigId: string, from: string, to: string, extraHttpRequestParams?: any): Observable<Array<ApiResponseUserTransactionsExport>>;

    /**
     * 
     * 
     * @param employerId 
     * @param rewardStyleConfigId 
     * @param from 
     * @param to 
     */
    rewardsControllerGetTransactions1(employerId: string, rewardStyleConfigId: string, from: string, to: string, extraHttpRequestParams?: any): Observable<Array<ApiResponseUserTransactionsExport>>;

    /**
     * 
     * 
     * @param username 
     * @param apiRequestManuallyReward 
     */
    rewardsControllerManuallyReward0(username: string, apiRequestManuallyReward: ApiRequestManuallyReward, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param username 
     * @param apiRequestManuallyReward 
     */
    rewardsControllerManuallyReward1(username: string, apiRequestManuallyReward: ApiRequestManuallyReward, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestRedeemModel 
     */
    rewardsControllerRedeem0(apiRequestRedeemModel: ApiRequestRedeemModel, extraHttpRequestParams?: any): Observable<ApiResponseRedeemModel>;

    /**
     * 
     * 
     * @param apiRequestRedeemModel 
     */
    rewardsControllerRedeem1(apiRequestRedeemModel: ApiRequestRedeemModel, extraHttpRequestParams?: any): Observable<ApiResponseRedeemModel>;

    /**
     * 
     * 
     * @param apiRequestResetAccountBalance 
     */
    rewardsControllerResetAccountBalanceByEmployerId0(apiRequestResetAccountBalance: ApiRequestResetAccountBalance, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestResetAccountBalance 
     */
    rewardsControllerResetAccountBalanceByEmployerId1(apiRequestResetAccountBalance: ApiRequestResetAccountBalance, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestUserReferredModel 
     */
    rewardsControllerRewardForUserReferred0(apiRequestUserReferredModel: ApiRequestUserReferredModel, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param apiRequestUserReferredModel 
     */
    rewardsControllerRewardForUserReferred1(apiRequestUserReferredModel: ApiRequestUserReferredModel, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param file 
     */
    rewardsControllerRewardMultipleViaCSV0(file: Blob, extraHttpRequestParams?: any): Observable<ApiResponseCSVReward>;

    /**
     * 
     * 
     * @param file 
     */
    rewardsControllerRewardMultipleViaCSV1(file: Blob, extraHttpRequestParams?: any): Observable<ApiResponseCSVReward>;

}
