import { Injectable } from '@angular/core';

import { AppService } from '@swagger/index';
import { Observable } from 'rxjs';
import { DashboardLanguageService } from './dashboard-language.service';
import { HttpHelperService } from './http-helper.service';

@Injectable({
  providedIn: 'root',
})
export class LegalLinksWrapperService {
  constructor(
    private readonly dashboardLanguageService: DashboardLanguageService,
    private readonly appService: AppService,
    private readonly httpHelperService: HttpHelperService
  ) {}

  public getTermsAndConditionsLink(silentLoading = true): Observable<string> {
    const options = this.httpHelperService.getDefaultBypassLoadingOptions(silentLoading);

    return this.appService.appControllerGetTermsUrl0(
      this.dashboardLanguageService.getCurrentDashboardLanguage(),
      undefined,
      undefined,
      options
    );
  }

  public getPrivacyPolicyLink(silentLoading = true): Observable<string> {
    const options = this.httpHelperService.getDefaultBypassLoadingOptions(silentLoading);

    return this.appService.appControllerGetPrivacyUrl0(
      this.dashboardLanguageService.getCurrentDashboardLanguage(),
      undefined,
      undefined,
      options
    );
  }
}
