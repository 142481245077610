import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { ToastService } from '../../../../../core/services/toast/toast.service';

@Component({
  selector: 'upload-area',
  templateUrl: './upload-area.component.html',
  styleUrls: ['./upload-area.component.scss'],
})
export class UploadAreaComponent {
  @ViewChild('fileInput', { static: false }) public fileInput!: ElementRef;

  @Output() public fileImport: EventEmitter<File> = new EventEmitter();

  public file: File | null = null;

  constructor(private readonly toastService: ToastService) {}

  /**
   * Method which is called when a new file is uploaded per button or drag and drop
   * @param files
   */
  public onFileUpload(files: FileList | Event): void {
    const uploadedFile: FileList | Event = files instanceof Event ? ((files.target as any).files as FileList) : files;

    if (uploadedFile && uploadedFile.length === 0) {
      return;
    }

    if (uploadedFile.length > 1) {
      this.toastService.show(true, 'csvUpload.onlyOneFile');

      return;
    }

    if (uploadedFile[0].type !== 'text/csv') {
      this.toastService.show(true, 'csvUpload.onlyInCsvFormat');

      return;
    }

    this.file = uploadedFile[0];
  }

  public onImport(): void {
    this.fileImport.emit(this.file!);

    // clear file after importing
    this.removeFile();
  }

  /**
   * Method which is called when a new files gets uploaded
   */
  public onFileUploadButton(): void {
    this.fileInput.nativeElement.click();
  }

  public removeFile(): void {
    this.fileInput.nativeElement.value = null;
    this.file = null;
  }
}
