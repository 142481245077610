/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildAddressModel } from './childAddressModel';
import { ApiResponseFullStatisticForUserCo2SavedByModeInKg } from './apiResponseFullStatisticForUserCo2SavedByModeInKg';


export interface ApiResponseFullStatisticForUser { 
    co2SavedByModeInKg: ApiResponseFullStatisticForUserCo2SavedByModeInKg;
    /**
     * How many of the commutes where detected automatically and did not require the manual switch of possibleCommute to commute by user.              Trips where the mode was changed by the user are still counted as gps validated
     */
    percentageGpsValidated: number;
    displayName: string;
    email: string;
    registeredAt: string;
    homeZipCode?: string;
    workAddress?: ChildAddressModel;
    totalKm: number;
    kmByMode: object;
    totalCo2SavedInKg: number;
    totalSustainableCommutes: number;
    totalCarCommutes: number;
    totalRedeemedCoins: number;
    totalCollectedCoins: number;
}

