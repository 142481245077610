/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';



import { Configuration }                                     from '../configuration';



export interface AppServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     */
    appControllerGetClientIp0(extraHttpRequestParams?: any): Observable<object>;

    /**
     * 
     * 
     */
    appControllerGetClientIp1(extraHttpRequestParams?: any): Observable<object>;

    /**
     * 
     * 
     * @param locale 
     */
    appControllerGetPrivacyUrl0(locale: string, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param locale 
     */
    appControllerGetPrivacyUrl1(locale: string, extraHttpRequestParams?: any): Observable<string>;

    /**
     * Defines status routes for checking up on the server Returns a custom pingdom xml response as defined here: https://www.pingdom.com/blog/new-pingdom-feature-custom-monitoring-type/  Where OK/NOT OK indicates our ability to reach the database.
     * 
     */
    appControllerGetStatus0(extraHttpRequestParams?: any): Observable<string>;

    /**
     * Defines status routes for checking up on the server Returns a custom pingdom xml response as defined here: https://www.pingdom.com/blog/new-pingdom-feature-custom-monitoring-type/  Where OK/NOT OK indicates our ability to reach the database.
     * 
     */
    appControllerGetStatus1(extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param locale 
     */
    appControllerGetTermsUrl0(locale: string, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param locale 
     */
    appControllerGetTermsUrl1(locale: string, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param encryptedUsername 
     */
    appControllerUnsubscribeEmail0(encryptedUsername: string, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param encryptedUsername 
     */
    appControllerUnsubscribeEmail1(encryptedUsername: string, extraHttpRequestParams?: any): Observable<string>;

}
