import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { VerifiedEmailRequiredDirective } from '@shared/directives/verified-email-required.directive';
import { AppIframeComponent } from '@shared/standalone/app-iframe/app-iframe.component';
import { InfoExplanationComponent } from '@shared/standalone/info-explanation/info-explanation.component';
import { QRCodeModule } from 'angularx-qrcode';
import { NgOtpInputModule } from 'ng-otp-input';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { BackNavigationTitleComponent } from './components/back-navigation-title/back-navigation-title.component';
import { CalendarRangeSelectorComponent } from './components/calendar-range-selector/calendar-range-selector.component';
import { CommuteDirectionSelectorComponent } from './components/commute-direction-selector/commute-direction-selector.component';
import { CopyToClipboardComponent } from './components/copy-to-clipboard/copy-to-clipboard.component';
import { DateSelectorInputComponent } from './components/date-selector-input/date-selector-input.component';
import { DestinationAddressDialogComponent } from './components/destination-address-dialog/destination-address-dialog.component';
import { DrawTerritoryMapComponent } from './components/draw-territory-map/draw-territory-map.component';
import { CropperDialogComponent } from './components/edit-image-upload/components/cropper-dialog/cropper-dialog.component';
import { EditImageUploadComponent } from './components/edit-image-upload/edit-image-upload.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { ErrorStateComponent } from './components/error-state/error-state.component';
import { FreeTrialBannerComponent } from './components/free-trial-banner/free-trial-banner.component';
import { GooglePlacesInputMapPreviewComponent } from './components/google-places-input-map-preview/google-places-input-map-preview.component';
import { GooglePlacesInputComponent } from './components/google-places-input/google-places-input.component';
import { KpiCardComponent } from './components/kpi-card/kpi-card.component';
import { KpiGridComponent } from './components/kpi-grid/kpi-grid.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';
import { LoggedInPagesLayoutComponent } from './components/logged-in-pages-layout/logged-in-pages-layout.component';
import { ModeSelectorComponent } from './components/mode-selector/mode-selector.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { NothingFoundHintComponent } from './components/nothing-found-hint/nothing-found-hint.component';
import { PaymentPendingBannerComponent } from './components/payment-pending-banner/payment-pending-banner.component';
import { PointTypeSelectorComponent } from './components/point-type-selector/point-type-selector.component';
import { RoleSelectorComponent } from './components/role-selector/role-selector.component';
import { SidebarContentComponent } from './components/sidebar-content/sidebar-content.component';
import { SidebarDownloadAppHintComponent } from './components/sidebar-download-app-hint/sidebar-download-app-hint.component';
import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';
import { SkeletonImageComponent } from './components/skeleton-image/skeleton-image.component';
import { SubscriptionExpiredDialogComponent } from './components/subscription-expired-dialog/subscription-expired-dialog.component';
import { SubscriptionExpiresSoonBannerComponent } from './components/subscription-expires-soon-banner/subscription-expires-soon-banner.component';
import { TagComponent } from './components/tag/tag.component';
import { TimeFrameSelectorCardComponent } from './components/time-frame-selector-card/time-frame-selector-card.component';
import { TimeFrameSelectorComponent } from './components/time-frame-selector/time-frame-selector.component';
import { UserCheckboxCardComponent } from './components/user-checkbox-card/user-checkbox-card.component';
import { UserInsightCheckboxCardComponent } from './components/user-insight-checkbox-card/user-insight-checkbox-card.component';

import { MapVisualizationComponent } from './components/transit-validation-result/map-visualization/map-visualization.component';
import { TransitValidationResultComponent } from './components/transit-validation-result/transit-validation-result.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { RawDataDialogComponent } from './dialogs/raw-data-dialog/raw-data-dialog.component';
import { SelectUsersDialogComponent } from './dialogs/select-users-dialog/select-users-dialog.component';
import { CollapseAnimationDirective } from './directives/collapse-animation.directive';
import { OverflowMarqueeAnimationDirective } from './directives/overflow-marquee-animation.directive';
import { PreventDoubleClickDirective } from './directives/prevent-double-click.directive';
import { RawDataDirective } from './directives/raw-data.directive';
import { TimedAutofocusDirective } from './directives/timed-autofocus.directive';
import { FileUploadModule } from './modules/file-upload/file-upload.module';
import { MaterialCdkModule } from './modules/material-cdk/material-cdk.module';
import { MaterialModule } from './modules/material/material.module';
import { BackendTranslatePipe } from './pipes/backend-translate.pipe';
import { EmptyDataHyphenPipe } from './pipes/empty-data-hyphen.pipe';
import { LocalizeMeterPipe } from './pipes/localize-meter.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { TypeCastPipe } from './pipes/type-cast.pipe';

@NgModule({
  imports: [
    GoogleMapsModule,
    CommonModule,
    RouterModule,
    TranslateModule,
    MaterialModule,
    MaterialCdkModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxJsonViewerModule,
    FileUploadModule,
    ImageCropperComponent,
    QRCodeModule,
    InfoExplanationComponent,
    BackNavigationTitleComponent,
    AppIframeComponent,
    SafeUrlPipe,
    NgOtpInputModule,
    SafeHtmlPipe,
    VerifiedEmailRequiredDirective,
  ],
  exports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MaterialModule,
    MaterialCdkModule,
    NothingFoundHintComponent,
    TagComponent,
    EmptyStateComponent,
    TypeCastPipe,
    OverflowMarqueeAnimationDirective,
    RawDataDirective,
    BackendTranslatePipe,
    MatDatepickerModule,
    MatNativeDateModule,
    FileUploadModule,
    TimeFrameSelectorComponent,
    GooglePlacesInputComponent,
    ModeSelectorComponent,
    PointTypeSelectorComponent,
    VerifiedEmailRequiredDirective,
    CommuteDirectionSelectorComponent,
    SafeUrlPipe,
    EmptyDataHyphenPipe,
    GoogleMapsModule,
    LocalizeMeterPipe,
    PreventDoubleClickDirective,
    SkeletonImageComponent,
    KpiCardComponent,
    BackNavigationTitleComponent,
    KpiGridComponent,
    LanguageSelectorComponent,
    RoleSelectorComponent,
    CalendarRangeSelectorComponent,
    SelectUsersDialogComponent,
    UserCheckboxCardComponent,
    UserInsightCheckboxCardComponent,
    DateSelectorInputComponent,
    EditImageUploadComponent,
    DestinationAddressDialogComponent,
    CopyToClipboardComponent,
    TimedAutofocusDirective,
    GooglePlacesInputMapPreviewComponent,
    LanguageSwitchComponent,
    CollapseAnimationDirective,
    ErrorStateComponent,
    SubscriptionExpiredDialogComponent,
    TimeFrameSelectorCardComponent,
    TransitValidationResultComponent,
    NgOtpInputModule,
  ],
  declarations: [
    LoggedInPagesLayoutComponent,
    TagComponent,
    SidebarContentComponent,
    NotfoundComponent,
    NothingFoundHintComponent,
    EmptyStateComponent,
    TypeCastPipe,
    OverflowMarqueeAnimationDirective,
    BackendTranslatePipe,
    ConfirmDialogComponent,
    RawDataDirective,
    RawDataDialogComponent,
    SidebarMenuComponent,
    TimeFrameSelectorComponent,
    GooglePlacesInputComponent,
    ModeSelectorComponent,
    PointTypeSelectorComponent,
    CommuteDirectionSelectorComponent,
    EmptyDataHyphenPipe,
    LocalizeMeterPipe,
    PreventDoubleClickDirective,
    SkeletonImageComponent,
    KpiCardComponent,
    KpiGridComponent,
    RoleSelectorComponent,
    LanguageSelectorComponent,
    CalendarRangeSelectorComponent,
    SelectUsersDialogComponent,
    UserCheckboxCardComponent,
    UserInsightCheckboxCardComponent,
    DateSelectorInputComponent,
    EditImageUploadComponent,
    CropperDialogComponent,
    DestinationAddressDialogComponent,
    DrawTerritoryMapComponent,
    CopyToClipboardComponent,
    TimedAutofocusDirective,
    GooglePlacesInputMapPreviewComponent,
    LanguageSwitchComponent,
    FreeTrialBannerComponent,
    SidebarDownloadAppHintComponent,
    CollapseAnimationDirective,
    ErrorStateComponent,
    PaymentPendingBannerComponent,
    SubscriptionExpiresSoonBannerComponent,
    SubscriptionExpiredDialogComponent,
    TimeFrameSelectorCardComponent,
    TransitValidationResultComponent,
    MapVisualizationComponent,
  ],
})
export class SharedModule {}
