import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Region } from '@core/enums/region.enum';
import { GoogleAnalyticsService } from '@core/services/google-analytics/google-analytics.service';
import { LegalLinksWrapperService } from '@core/services/legal-links-wrapper.service';
import { MailerooWrapperService } from '@core/services/maileroo-wrapper.service';
import { ToastService } from '@core/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageKey } from '@shared/enums/session-storage-key.enum';
import { companyEmailValidator } from '@shared/validators/company-email-verification.validator';
import { emailVerificationValidator, ExtendedControl } from '@shared/validators/email-verification.validator';
import { take } from 'rxjs';
import { SignupForm } from '../../interfaces/signup-form.interface';

@Component({
  selector: 'signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.scss'],
})
export class SignupFormComponent implements OnInit {
  public form!: FormGroup<SignupForm>;

  @Input() public region!: Region;

  @Output() public signup: EventEmitter<string> = new EventEmitter();

  @Output() public setSessionStorage: EventEmitter<string | null> = new EventEmitter();

  public termsAndConditionsLink?: string;

  public privacyPolicyLink?: string;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly legalLinksWrapperService: LegalLinksWrapperService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly mailerooService: MailerooWrapperService,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.translateService.onLangChange.pipe(takeUntilDestroyed()).subscribe(() => {
      this.termsAndConditionsLink = undefined;
      this.privacyPolicyLink = undefined;
      this.loadLegalLinks();
    });
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group<SignupForm>({
      email: this.formBuilder.control(sessionStorage.getItem(SessionStorageKey.registerEmail), {
        validators: [Validators.required, Validators.email, companyEmailValidator(this.googleAnalyticsService)],
        asyncValidators: [emailVerificationValidator(this.mailerooService)],
      }),
      legal: this.formBuilder.control(null, [Validators.requiredTrue]),
    });

    if (this.form.controls.email.value) {
      this.form.controls.email.markAsTouched();
    }

    this.loadLegalLinks();
  }

  private scheduleSubmit(): void {
    this.form.statusChanges.pipe(take(1)).subscribe((status) => {
      if (status !== 'PENDING') {
        this.onSubmit();
      }
    });
  }

  private loadLegalLinks(): void {
    this.loadTermsAndConditionsLink();
    this.loadPrivacyPolicyLink();
  }

  private loadTermsAndConditionsLink(isReload = false): void {
    const silentLoading = isReload === false;

    this.legalLinksWrapperService.getTermsAndConditionsLink(silentLoading).subscribe({
      next: (link) => {
        this.termsAndConditionsLink = link;

        if (isReload) {
          this.navigateToExternalUrl(link);
        }
      },
      error: () => {
        if (isReload) {
          this.showNavigationError();
        }
      },
    });
  }

  private loadPrivacyPolicyLink(isReload = false): void {
    const silentLoading = isReload === false;

    this.legalLinksWrapperService.getPrivacyPolicyLink(silentLoading).subscribe({
      next: (link) => {
        this.privacyPolicyLink = link;

        if (isReload) {
          this.navigateToExternalUrl(link);
        }
      },
      error: () => {
        if (isReload) {
          this.showNavigationError();
        }
      },
    });
  }

  private navigateToExternalUrl(url: string): void {
    window.open(url, '_blank');
  }

  private showNavigationError(): void {
    this.toastService.show(true, this.translateService.instant('signup-form.redirectToLegalLinkError'));
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();

    if (this.form.pending) {
      this.scheduleSubmit();

      return;
    }

    if (this.form.valid) {
      this.signup.emit(this.form.value.email!);
    }
  }

  public onLegalClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    if (target.tagName.toLowerCase() !== 'a') {
      return;
    }

    if (target.id === 'privacyPolicy' && !this.privacyPolicyLink) {
      event.preventDefault();
      this.loadPrivacyPolicyLink(true);

      return;
    }

    if (target.id === 'termsAndConditions' && !this.termsAndConditionsLink) {
      event.preventDefault();
      this.loadTermsAndConditionsLink(true);
    }
  }

  public onChange(): void {
    this.setSessionStorage.emit(this.form.value.email);
  }

  public onClearEmail(): void {
    this.form.controls.email.reset();
    sessionStorage.removeItem(SessionStorageKey.registerEmail);
  }

  public onSuggestionUse(correctedEmail: string): void {
    this.form.controls.email.patchValue(correctedEmail);
    this.form.controls.email.updateValueAndValidity();
    this.onChange();
  }

  public get emailDomainSuggestionWarning(): string {
    return (this.form.controls.email as ExtendedControl).warnings?.domainSuggestion;
  }
}
