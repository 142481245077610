/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestUserConnectionCount } from '../model/models';
import { ApiRequestUserConnectionStatistics } from '../model/models';
import { ApiResponseConnectedUsersEntryModel } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface UserConnectionEventServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param apiRequestUserConnectionCount 
     */
    userConnectionEventControllerGetCurrentCOuntByEmployer0(apiRequestUserConnectionCount: ApiRequestUserConnectionCount, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param apiRequestUserConnectionCount 
     */
    userConnectionEventControllerGetCurrentCOuntByEmployer1(apiRequestUserConnectionCount: ApiRequestUserConnectionCount, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param apiRequestUserConnectionStatistics 
     */
    userConnectionEventControllerGetTimelineByEmployer0(apiRequestUserConnectionStatistics: ApiRequestUserConnectionStatistics, extraHttpRequestParams?: any): Observable<Array<ApiResponseConnectedUsersEntryModel>>;

    /**
     * 
     * 
     * @param apiRequestUserConnectionStatistics 
     */
    userConnectionEventControllerGetTimelineByEmployer1(apiRequestUserConnectionStatistics: ApiRequestUserConnectionStatistics, extraHttpRequestParams?: any): Observable<Array<ApiResponseConnectedUsersEntryModel>>;

}
