/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestInviteUser } from '../model/models';
import { ApiRequestInviteUsers } from '../model/models';
import { ApiResponseNotInvitedUsers } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface EmployerInviteServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param employerId 
     * @param file 
     */
    employerInviteControllerImportUsers0(employerId: string, file: Blob, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param employerId 
     * @param file 
     */
    employerInviteControllerImportUsers1(employerId: string, file: Blob, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param apiRequestInviteUser 
     */
    employerInviteControllerInviteUser0(apiRequestInviteUser: ApiRequestInviteUser, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestInviteUser 
     */
    employerInviteControllerInviteUser1(apiRequestInviteUser: ApiRequestInviteUser, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestInviteUsers 
     */
    employerInviteControllerInviteUsers0(apiRequestInviteUsers: ApiRequestInviteUsers, extraHttpRequestParams?: any): Observable<ApiResponseNotInvitedUsers>;

    /**
     * 
     * 
     * @param apiRequestInviteUsers 
     */
    employerInviteControllerInviteUsers1(apiRequestInviteUsers: ApiRequestInviteUsers, extraHttpRequestParams?: any): Observable<ApiResponseNotInvitedUsers>;

}
