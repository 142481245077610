/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RewardImage } from './rewardImage';
import { TangoRewardStyle } from './tangoRewardStyle';


export interface ChildTangoRewardStyleConfig { 
    style: TangoRewardStyle;
    catalogId?: string;
    minAmount: number;
    active: boolean;
    purchaseCountLimitForUser?: number;
    purchaseCoinLimitForUser?: number;
    availableFrom?: string;
    availableTo?: string;
    images?: Array<RewardImage>;
    sendManagerEmail?: boolean;
    /**
     * ID of the reward style. This needs to be set for the app only
     */
    id?: string;
    /**
     * ID is used to be sent along with the dashboard to not trigger reassignment of ObjectId()
     */
    _id?: string;
}



